import { defineStore } from "pinia";
import { Paths } from "@/assets/docs/client.d";

export interface IssuerState {
  all: Paths.PublicGetProductList.Responses.$200 | null;
  info: Paths.PublicGetProductDetailsByIsin.Responses.$200 | null;
  issuer: Paths.PublicGetIssuer.Responses.$200 | null;
  valuationHistory: Paths.PublicGetValuationHistoryForProduct.Responses.$200 | null;
}

export const usePublicIssuerStore = defineStore("PublicIssuerStore", {
  state: (): IssuerState => ({
    all: null,
    info: null,
    issuer: null,
    valuationHistory: null,
  }),

  actions: {
    reset() {
      this.info = null;
      this.valuationHistory = null;
    },
    async loadProduct({ slug, isin }) {
      if (this.info?.isin && isin !== this.info?.isin) {
        this.reset();
      }
      const response = await this.apiClient.PublicGetProductDetailsByIsin({
        slug: slug,
        isin: isin,
      });

      if (response.status === 200) {
        this.info = response.data;
      } else {
        return Promise.reject(response);
      }
    },
    async loadIssuer(issuerUuid) {
      this.issuer = {};
      const response = await this.apiClient.PublicGetIssuer(issuerUuid);
      this.issuer = response.data;
    },
    async loadAllProducts(issuerUuid) {
      const response = await this.apiClient.PublicGetProductList(issuerUuid);
      this.all = response.data?.products;
    },
    async loadValuationHistory({ slug, isin }) {
      this.valuationHistory = null;

      const response = await this.apiClient.PublicGetValuationHistoryForProduct({
        slug: slug,
        isin: isin,
      });

      if (response.data) {
        this.valuationHistory = response.data.reverse();
      } else {
        this.valuationHistory = [];
      }
    },
  },
});
